.products-main{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3rem 2rem;
    height: 100vh;
    /* min-height: 100vh; */
    
}

.products-core{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content:center;
    gap: 3rem;
    width: 100%;
}
.products-cards-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 0 1rem;
  }

  .products-heading-text{
    font-family: var(--font-family-montserrat);
    font-weight: 600;
    margin: 0px;
    text-align: center;
  }

  .products-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 350px;
    height: fit-content;
    border-radius: 20px;
    padding: 0 0 1rem 0;
    backdrop-filter: blur(50px);
    box-shadow: 0px 0px 5px 1.5px rgb(238, 233, 233);
    transition: 0.5s;
  }
  .products-card:hover{
    scale: 1.1;
  }
  .products-card-text{
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    
  }
  .products-front {
    background-image: var(--bg-image);
    box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
    color: white;
    display: flex;
    flex-direction: column;
    height: 95%;
    border-radius: 30px;
  }
  
  .products-img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    
  }

  .product-name {
    text-align: center;
    font-family: var(--font-family-montserrat);
    font-size: 22px;
    font-weight: 800;
    padding: 0 0 0 0;
    margin: 0px;
  }
  
  
  
  .product-desc{
    margin:0;
  }
  
  