.careers-main{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2rem;
    
    
}
.careers-core{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    
    
}
.careers-title-name-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
  }
.careers-cards-row{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    align-items: center;
    justify-content: space-evenly;
  }
  .careers-cards-row2{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .careers-benifits-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
  }
.careers-heading2{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}
.careers-heading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
  .careers-heading-text{
    font-family: var(--font-family-montserrat);
    font-weight: 600;
    font-size: 40px;
    margin: 0px;
    text-align: center;
  }
  .careers-heading-text2{
    font-size: 25px;
    font-weight: 500;
  }
  .careers-heading-text3{
    font-size: 50px;
    color:#d7df23
  }
  .careers-heading-text5{
    color:#d7df23
  }
  .careers-horizontal-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 800px;
    height: 250px;
    background-color: var(--member-plaque);
    border-radius: 15px;
  }
  
  .careers-horizontal-card-left{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  }
  .careers-horizontal-card-right{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }
  .careers-horizontal-card-userimage{
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  
  
  
  .careers-social-icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 25px;
  }
  
  .careers-vertical-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 450px;
    background-color: var(--member-plaque);
    border-radius: 15px;
    padding: 1rem;
  }
  .careers-vertical-card-userimage{
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .careers-verical-card-top{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .careers-vertical-card-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .careers-vertical-name-text{
    font-size: xx-large;
    font-family: var(--font-family-montserrat);
    font-weight: 600;
    text-align: center;
  }
  .careers-vertical-info-text{
    font-size: medium;
  }
  
  @media (max-width: 768px) {
    .careers-horizontal-card {
      flex-direction: column;
      width: auto;
      height: auto;
      text-align: center;
    }
  
    .careers-horizontal-card-left,
    .careers-horizontal-card-right {
      padding: 1rem;
    }
  
    .careers-horizontal-card-userimage {
      width: 150px;
      height: 150px;
    }
  
    .careers-vertical-card {
      width: auto;
      height: auto;
    }
  
    .careers-vertical-card-userimage {
      width: 150px;
      height: 150px;
    }
  
    .careers-verical-card-top {
      margin-bottom: 1rem;
    }
  }
  
  
  .main-cards {
    display: grid;
    grid-template-columns: 300px 300px 300px 300px;
    row-gap: 50px;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
    margin-top: 80px;
  }
  
  .main-head {
    text-align: center;
    letter-spacing: 6px;
  
  }
  .careers-card-container {
    perspective: 1000px;
    
  }
  .careers-card-container2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
  }

  .careers-card-bottom-h2{
    margin: 0px;
    font-size: 18px;
  }
  .careers-card {
    max-width: 500px;
    width: 100%;
    height: 400px;
  }
  .careers-card2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .careers-front2{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    background-image: var(--bg-image);
    box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
    color: white;
    gap: 3rem;
    border-radius: 10px;
  }
  .careers-input-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 5rem;
    align-items: center;
  }
  .careers-input-container-vertical{
    flex-direction: column;
  }
  .careers-front{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 0 1rem 0;
    align-items: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    background-image: var(--bg-image);
    box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
    color: white;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
  }
  
  
.careers-card-jobs-text{
    padding: 1rem;
    
  }
  
  
  .product-desc{
    
    text-align: center;
    padding: 0 1rem;
  }
  
  .product-jobs-desc{
    text-align: center;
  }
  
  .careers-img {
    width: 100%;
    height: 50%;
    padding-top: 0px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
  }
  .careers-card-text{
    padding: 10px 0px;
  }
  .heading,
  .product-name {
    text-align: left;
    font-family: var(--font-family-montserrat);
    padding-left: 20px;
    margin: 0px;
  
  
  }
  .product-name {
    text-align: center;
    font-family: var(--font-family-montserrat);
    font-size: 22px;
    font-weight: 800;
    padding: 0 0 0 0;
    margin: 0px;
  
  
  }
  
  .careers-title{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  
  
  .role {
    font-size: 16px;
    text-align: left;
    
    margin: 0px;
    padding: 0 0 0 20px;
    font-family: var(--font-family-montserrat);
  }
  
  .signature-container{
    display: flex;
    justify-content: flex-end;
    border-radius: 30px;
    padding-right: 1rem;  
  }
  
  .signature {
    width: 50px;
    border-radius: 15px;
  
  }
  
  
  
  .careers-social-media-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .careers-logos {
    height: 1.3em;
    margin: 0;
    padding: 0;
  
  
  }
  .logos:hover {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7)) brightness(150%); 
  
}
  
  

.careers-jobs-card-container{
    padding: 1rem 1rem;
    margin: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
  }

  
.careers-jobs-card {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    
}
  
  
.careers-jobs-front{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 1rem 0;
    align-items: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
    color: white;
    border-radius: 10px;
}
.product-jobs-desc{
    text-align: center;
}

.careers-jobs-img {
    width: 100%;
    height: 50%;
    padding-top: 0px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.product-jobs-name {
    text-align: center;
    font-family: var(--font-family-montserrat);
    font-size: 22px;
    font-weight: 800;
    padding: 0 0 0 0;
    margin: 0px;
  
  
}
.careers-jobs-carousel{
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 15px;
    
}
  
  
  
  
























.input-container {
  position: relative;
}

.input {
  padding: 10px;
  height: 40px;
  border: 2px solid #ffffff;
  border-top: none;
  border-bottom: none;
  font-size: 16px;
  background: transparent;
  outline: none;
  box-shadow: 7px 7px 0px 0px #ffffff;
  transition: all 0.5s;
}

.input:focus {
  box-shadow: none;
  transition: all 0.5s;
}
.input[type="file"]:hover {
  box-shadow: none;
  transition: all 0.5s;
}
.input-hover:hover{
  box-shadow: none;
  transition: all 0.5s;
}

.label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ffffff;
  transition: all 0.5s;
  transform: scale(0);
  z-index: -1;
}

.input-container .topline {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 0%;
  height: 2px;
  right: 0;
  top: 0;
  transition: all 0.5s;
}

.input-container input[type="text"]:focus ~ .topline1 {
  width: 92%;
  transition: all 0.5s;
}
.input-container input[type="text"]:focus ~ .topline2 {
  width: 90%;
  transition: all 0.5s;
}
.input-container input[type="text"]:focus ~ .topline6 {
  width: 93%;
  transition: all 0.5s;
}
.input-container input[type="text"]:focus ~ .topline3 {
  width: 76%;
  transition: all 0.5s;
}
.input-container input[type="file"]:hover ~ .topline4 {
  width: 90%;
  transition: all 0.5s;
}
.input-job-role{
  color: black;
  background-color: transparent;
}
.input-container .input-hover:hover ~ .topline5 {
  width: 88%;
  transition: all 0.5s;
}
.input-container .input-hover:hover ~ .label {
  top: -10px;
  transform: scale(1);
  transition: all 0.5s;
}
.input-container .input-hover:hover ~ .underline {
  width: 100%;
  transition: all 0.5s;
}

.input-container .underline {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 0%;
  height: 2px;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
}
.input{
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: white;
}
.input-container input[type="text"]:focus ~ .underline {
  width: 100%;
  transition: all 0.5s;
}
.input-container input[type="file"]:hover ~ .underline {
  width: 100%;
  transition: all 0.5s;
}
.input-container{
  width: 100%;
}
.input-container input[type="text"]:focus ~ .label {
  top: -10px;
  transform: scale(1);
  transition: all 0.5s;
}


.input-message{
  height: 200px;
}
























.careers-application-submit-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-family: inherit;
  font-size: 15px;
 }
 
 .careers-application-submit-btn {
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 1.25em 2em;
  background: #fff0f0;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: background 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1);
 }
 
 .careers-application-submit-btn::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d7df23;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  -webkit-transform: translate3d(0, 0.75em, -1em);
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
 }
 
 .careers-application-submit-btn:hover {
  background: #ffe9e9;
  -webkit-transform: translate(0, 0.25em);
  transform: translate(0, 0.25em);
 }
 
 .careers-application-submit-btn:hover::before {
  -webkit-box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
  box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
  -webkit-transform: translate3d(0, 0.5em, -1em);
  transform: translate3d(0, 0.5em, -1em);
 }
 
 .careers-application-submit-btn:active {
  background: #d7df23;
  -webkit-transform: translate(0em, 0.75em);
  transform: translate(0em, 0.75em);
 }
 
 .careers-application-submit-btn:active::before {
  -webkit-box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  -webkit-transform: translate3d(0, 0, -1em);
  transform: translate3d(0, 0, -1em);
 }













































 .careers-application-submit-animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
  box-shadow: 0 0 0 2px #000000;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.careers-application-submit-animated-button svg {
  position: absolute;
  width: 24px;
  fill: black;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.careers-application-submit-animated-button .careers-application-submit-arr-1 {
  right: 16px;
}

.careers-application-submit-animated-button .careers-application-submit-arr-2 {
  left: -25%;
}

.careers-application-submit-animated-button .careers-application-submit-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #DFD723;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.careers-application-submit-animated-button .careers-application-submit-text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.careers-application-submit-animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: black;
  border-radius: 12px;
}

.careers-application-submit-animated-button:hover .careers-application-submit-arr-1 {
  right: -25%;
}

.careers-application-submit-animated-button:hover .careers-application-submit-arr-2 {
  left: 16px;
}

.careers-application-submit-animated-button:hover .careers-application-submit-text {
  transform: translateX(12px);
}

.careers-application-submit-animated-button:hover svg {
  fill: black;
}

.careers-application-submit-animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px #dfd723;
}

.careers-application-submit-animated-button:hover .careers-application-submit-circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}
