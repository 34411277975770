.about2-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 2rem;
  padding: 2rem;
  /* padding-top: 0rem; */
  color: black;
}

.about2-core {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about2-hero-text {
  font-family: var(--font-family-montserrat);
  color: white;
  text-align: center;
  font-size: 3rem;
  width: 100%;
}
.about2-logo-image {
  height: 150px;
}
.about2-Carousel {
  width: 100%;
  border-radius: 15px;
}
.about2-card {
  padding: 0 1rem;
}
.about2-product-image {
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 400px;
  border-radius: 15px;
  justify-content: center;
  align-self: center;
  object-fit: cover;
}

.about2-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.about2-horizontal-card {
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 3rem;
  justify-content: space-around;
  align-items: center;
  backdrop-filter: blur(50px);
  border-radius: 15px;
  box-shadow: 0px 0px 25px white;
  /* height: 400px; */
}
.about2-horizontal-card-left {
  flex-direction: row-reverse;
}
.about2-horizontal-card-flex {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.about2-img {
  border-radius: 15px;
  height: 350px;
}
.about2-span {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.about2-span p {
  font-size: 18px;
}
.about2-span h1 {
  color: var(--fg-color);
  font-size: 40px;
}
.about2-span2 {
  text-align: center;
}
.about2-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .about2-horizontal-card {
    flex-direction: column;
  }
  .about2-span {
    text-align: center;
    width: 100%;
  }
  .about2-img {
    width: 100%;
    aspect-ratio: 1/1;
  }
  .about2-logo-image {
    width: 50%;
    height: 50%;
  }
  .about2-hero-text {
    font-size: 2rem;
  }
}

@media (max-width: 500px) {
  .about2-hero-text {
    font-size: 1rem;
  }
}
