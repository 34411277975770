

.signup-form__google{
  border-radius: 10px;
  overflow: hidden;
}
.signup-success-main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  
  }
  
  
  
  
.valid{
  color: black;
}
.invalid{
  color: black;
  padding-left: 0.5rem;
  
}
.instructions,.errmsg{
  display: flex;
  color: black;
  font-size: small;

}
.offscreen,.hide{
  display: none;
}
.signup-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;  
  justify-content: center;
  
}

.signup-main-core {
position: relative;


width: 1000px;
min-width: 1000px;
min-height: 600px;
height: 600px;
padding: 25px;
background-color: transparent;
backdrop-filter: blur(10px);
box-shadow:0px 0px 20px 0px rgb(0,0,0);
border-radius: 12px;
overflow: hidden;
}

.signup-container {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 0;
width: 600px;
height: 100%;
padding: 25px;
background-color: transparent;
backdrop-filter: blur(10px);
transition: 1.25s;
}

.signup-form {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;
height: 100%;

}
.signup-form__icon {
object-fit: contain;
width: 30px;
margin: 0 5px;
opacity: 1;
transition: 0.15s;

}
.signup-form__icon:hover {
opacity: 0.5;
transition: 0.15s;
cursor: pointer;
}
.signup-form__input {
width: 350px;
height: 40px;
margin: 4px 0;
padding-left: 25px;
font-size: 13px;
letter-spacing: 0.15px;
border: none;
outline: none;
font-family: var(--font-family-montserrat);
background-color: transparent;
backdrop-filter: blur(10px);
transition: 0.25s ease;
color: black;
border-radius: 8px;
box-shadow:0px 0px 5px 0px rgb(0,0,0);
}
.signup-form__input:focus {
box-shadow: inset 4px 4px 4px var(--glow-2), inset -4px -4px 4px var(--glow-2);
}
.signup-form__span {
  text-decoration: underline;
margin-top: 15px;
margin-bottom: 12px;
font-family: var(--font-family-montserrat);
color: black;
}
.signup-form__span1 {
  text-decoration: underline;
  margin-top: 0px;
  font-family: var(--font-family-montserrat);
  color: black;
  }
.signup-form__link {
color: black;
font-size: 15px;
font-family: var(--font-family-montserrat);
margin-top: 25px;
border-bottom: 1px solid var(--glow-2);
line-height: 2;
cursor: pointer;
}
.signup-form__login{
  text-decoration: underline;
  color: black;
  font-size: 15px;
  font-family: var(--font-family-montserrat);
  margin-top: 15px;
  margin-bottom: 5px;
  line-height: 2;
  cursor: pointer;
}
.signup-form__link:hover {
  text-shadow: 0px 0px 15px var(--glow-2);
}


.signup-title {
  font-size: 34px;
  font-weight: 700;
  font-family: var(--font-family-montserrat);
  line-height: 2;
  color: black;
}

.signup-description {
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6;
}




.signup-a-container {
z-index: 100;
left: calc(100% - 600px );
}

.signup-b-container {
left: calc(100% - 600px );
z-index: 0;
}

.signup-switch {
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 0;
left: 0;
height: 100%;
width: 400px;
padding: 50px;
z-index: 200;
transition: 1.25s;
background-color: transparent;
backdrop-filter: blur(10px);
overflow: hidden;
box-shadow:0px 0px 20px 0px rgb(0,0,0);
}

.signup-switch__container {
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
position: absolute;
width: 400px;
padding: 50px 55px;
transition: 1.25s;
}
.signup-switch__button {
cursor: pointer;
box-shadow:0px 0px 20px 0px rgb(0,0,0);
}
.signup-switch__button:hover {
box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
transform: scale(0.985);
transition: 0.25s;
}
.signup-switch__button:active, .switch__button:focus {
box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
transform: scale(0.97);
transition: 0.25s;
}
.signup-switch__description{
  color: black;
}
.signup-button {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--font-family-montserrat);
  letter-spacing: 1.15px;
  background-color: transparent;
  background-image: var(--bg-image);
  backdrop-filter: blur(10px);
  color: black;
  box-shadow:0px 0px 20px 0px rgb(0,0,0);
  border: none;
  outline: none;
}
.signup-success-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1000px;
    padding: 50px;
    z-index: 200;
    transition: 1.25s;
    background-color: transparent;
    backdrop-filter: blur(10px);
    
    overflow: hidden;
    
  }
  
  
  
  
  
  .signup-success-switch__button {
    cursor: pointer;
    box-shadow:0px 0px 20px 0px rgb(0,0,0);
  }
  
  .signup-success-switch__button:hover {
    box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
    transform: scale(0.985);
    transition: 0.25s;
  }
  



.signup-success-main-core {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 600px;
  padding: 25px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  box-shadow:0px 0px 20px 0px rgb(0,0,0);
  border-radius: 12px;
  overflow: hidden;
}



.signup-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 600px;
  height: 100%;
  padding: 25px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  transition: 1.25s;
}

.signup-success-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

}

.signup-success-form__icon {
  object-fit: contain;
  width: 30px;
  margin: 0 5px;
  opacity: 1;
  transition: 0.15s;

}

.signup-success-form__icon:hover {
  opacity: 0.5;
  transition: 0.15s;
  cursor: pointer;
}

.signup-success-form__input {
  width: 350px;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: var(--font-family-montserrat);
  background-color: transparent;
  backdrop-filter: blur(10px);
  transition: 0.25s ease;
  color: black;
  border-radius: 8px;
  box-shadow:0px 0px 20px 0px rgb(0,0,0);
}

.signup-success-form__input:focus {
  box-shadow: inset 4px 4px 4px var(--glow-2), inset -4px -4px 4px var(--glow-2);
}

.signup-success-form__span {
  margin-top: 30px;
  margin-bottom: 12px;
  font-family: var(--font-family-montserrat);
  color: black;
}

.signup-success-form__link {
  color: black;
  font-size: 15px;
  font-family: var(--font-family-montserrat);
  margin-top: 25px;
  border-bottom: 1px solid var(--glow-2);
  line-height: 2;
  cursor: pointer;
}
.signup-success-form__login{
  color: black;
  font-size: 15px;
  font-family: var(--font-family-montserrat);
  margin-top: 15px;
  margin-bottom: 5px;
  line-height: 2;
  cursor: pointer;
}

.signup-success-form__link:hover {


  text-shadow: 0px 0px 15px var(--glow-2);

}



.signup-success-title {
  font-size: 34px;
  font-weight: 700;
  align-items: center;
  text-align: center;
  font-family: var(--font-family-montserrat);
  color: black;
}

.signup-success-description {
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6;
}




.signup-success-a-container {
  z-index: 100;
  left: calc(100% - 600px);
}

.signup-success-b-container {
  left: calc(100% - 600px);
  z-index: 0;
}

.signup-success-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1000px;
  padding: 50px;
  z-index: 200;
  transition: 1.25s;
  background-color: transparent;
  backdrop-filter: blur(10px);
  
  overflow: hidden;
  
}



.signup-success-switch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  position: absolute;
  transition: 1.25s;
  padding: 0rem 5rem;
  flex-wrap: wrap;
}

.signup-success-switch__button {
  cursor: pointer;
  box-shadow:0px 0px 20px 0px rgb(0,0,0);
}

.signup-success-switch__button:hover {
  box-shadow: 6px 6px 10px var(--glow), -6px -6px 10px var(--glow);
  transform: scale(0.985);
  transition: 0.25s;
}

.signup-success-switch__button:active,
.switch__button:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.signup-success-switch__description {
  color: black;
}

.signup-success-button {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  font-weight: 700;
  font-size: 14px;
  font-family: var(--font-family-montserrat);
  letter-spacing: 1.15px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  color: black;
  box-shadow:0px 0px 20px 0px rgb(0,0,0);
  border: none;
  outline: none;
}

.signup-success-button:hover {
  box-shadow: 6px 6px 10px var(--glow-2), -6px -6px 10px var(--glow-2);
  transform: scale(0.985);
  transition: 0.25s;
}

.signup-success-button:active,
.signup-success-button:focus {
  box-shadow: 2px 2px 6px var(--glow-2), -2px -2px 6px var(--glow-2);
  transform: scale(0.97);
  transition: 0.25s;
}




  
  .signup-success-switch__button:active,
  .switch__button:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
  }
  
  .signup-success-switch__description {
    color: black;
  }
.signup-button:hover {
  box-shadow: 6px 6px 10px var(--glow-2), -6px -6px 10px var(--glow-2);
  transform: scale(0.985);
  transition: 0.25s;
}
.signup-button:disabled {
  box-shadow:0px 0px 20px 0px rgb(0,0,0);

transform: scale(0.985);
transition: 0.25s;
}
.signup-button:active, .signup-button:focus {
  box-shadow: 2px 2px 6px var(--glow-2), -2px -2px 6px var(--glow-2);
  transform: scale(0.97);
  transition: 0.25s;
}




.is-txr {
left: calc(100% - 400px );
transition: 1.25s;
transform-origin: left;
}

.is-txl {
left: 0;
transition: 1.25s;
transform-origin: right;
}

.is-z200 {
z-index: 200;
transition: 1.25s;
}

.is-hidden {
visibility: hidden;
opacity: 0;
position: absolute;
transition: 1.25s;
}

.is-gx {
animation: is-gx 1.25s;
}

@keyframes is-gx {
0%, 10%, 100% {
  width: 400px;
}
30%, 50% {
  width: 500px;
}
}