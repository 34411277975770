
.code-playground{
    background-color: rgb(25, 24, 27);
    width: 100%;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.code-editor-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    gap: 1rem;

}
.code-editor-core-container{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.output-container{
    width: 50%;
}


.code-editor-top-left-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.code-editor-btn-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.code-editor-top-info-text2{
    width: 100%;
    color: white;
    white-space: normal;
    margin: 0px;
    padding: 0px;
}
.code-editor-top-info-text{
    width: 50%;
    color: white;
    white-space: normal;
    margin: 0px;
    padding: 0px;
    

}
.code-editor-top-container{
    background-color: rgb(25, 24, 27);
    display: flex;
    gap: 1rem;
    
    align-items: center;
}


.run-btn {
    all: unset;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.6em 2em;
    border: mediumspringgreen solid 0.15em;
    border-radius: 0.25em;
    color: mediumspringgreen;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    transition: border 300ms, color 300ms;
    user-select: none;
}

.run-btn p {
z-index: 1;
}

.run-btn:hover {
color: #212121;
}

.run-btn:active {
border-color: teal;
}

.run-btn::after, .run-btn::before {
content: "";
position: absolute;
width: 9em;
aspect-ratio: 1;
background: mediumspringgreen;
opacity: 50%;
border-radius: 50%;
transition: transform 500ms, background 300ms;
}

.run-btn::before {
left: 0;
transform: translateX(-8em);
}

.run-btn::after {
right: 0;
transform: translateX(8em);
}

.run-btn:hover:before {
transform: translateX(-1em);
}

.run-btn:hover:after {
transform: translateX(1em);
}

.run-btn:active:before,
.run-btn:active:after {
background: teal;
}