

.sponsors-parent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:2rem;
    padding: 1rem 2rem;

}

.sponsors-head {
    color: white;
    font-size: 45px;
    text-align: center;
    font-family: var(--font-family-montserrat);
    font-weight: 600;
}


.sponsors-logoparent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);   
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
}
.sponsors-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;    
}

.sponsors-row:not(:first-child){
    border-top: 2px dotted white;
}
.sponsors-logo:not(:first-child){
    border-left: 2px dotted white;
}
.sponsors-logo{
    padding: 1rem;
    
    height: 200px;
    width: 200px;
}
.sponsors-logo:hover {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7)) brightness(150%); 
  }
@media (max-width:1308px){
    .sponsors-logo:last-child{
        border: none; 
        border-left:2px dotted white;
        border-right: 2px dotted white;
    }
    .sponsors-logo{
        border-bottom: 2px dotted white;
    }
}
@media (max-width:1108px){
    .sponsors-logo:nth-last-child(2){
        border: none; 
        border-left:2px dotted white;
    }
    .sponsors-logo{
        border-bottom: 2px dotted white;
    }
}
@media (max-width:908px){
    .sponsors-logo:nth-last-child(3){
        border: none; 
        
    }
    .sponsors-logo:last-child{
        border: none; 
        border-left:2px dotted white;
        border-right: none;
    }
    .sponsors-logo{
        border-bottom: 2px dotted white;
    }
    
}

@media (max-width:708px){
    .sponsors-logo:nth-child(even){
        border-left:2px dotted white;
    }
    .sponsors-logo:nth-child(odd){
        border-left:none;
    }
    .sponsors-logo:nth-child(n){
        border-bottom: 2px dotted white;
        
    }
    .sponsors-logo:nth-last-child(-n+2){
        border-bottom: none;
    }
}
@media (max-width:508px){
    .sponsors-logo:nth-child(even){
        border-left:none;
    }
    .sponsors-logo:nth-child(n){
        border-bottom: 2px dotted white;
    }
    .sponsors-logo:nth-last-child(1){
        border-bottom: none;
    }
}