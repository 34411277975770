.service-page-row-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  flex: 1;
  
  padding: 2rem 1rem;
  height: 100%;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
  overflow: scroll;
  backdrop-filter: blur(50px);
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px var(--fg-color);
}
.home-cards-row-title p {
  font-size: 15px;
  color: var(--bg-color);
}
.service-page-card-rating-text {
  font-weight: 1000;
  font-size: 20px;
  font-family: var(--font-family-space);
}

.home-cards-row-header {
  width: 100%;
  font-family: var(--font-family-akira);
  font-weight: 800;
  /* padding: 1rem 0rem; */
  text-transform: uppercase;
  letter-spacing: 5px;
  /* background-color: var(--fg-color); */
  text-align: left;
}
.home-cards-row-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
}
.service-page-countdown {
  display: flex;
  gap: 10px;
  border-radius: 15px;
  font-size: 30px;
  font-weight: 700;
}

.service-page-count-heading {
  font-size: 30px;
  font-weight: 700;
}
.service-page-main-count-heading {
  letter-spacing: 4px;
  font-size: 30px;
  font-weight: 600;
  font-family: var(--font-family-space);
  text-align: center;
}
.service-page-info p {
  font-family: var(--font-family-space);
  font-style: normal;
  font-size: 20px;
  color: var(--color-white);
}

.service-page-row {
  padding: 0 1rem;
  display: flex;
  gap: 1rem;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.service-page-heading-text {
  font-family: var(--font-family-space);
  font-weight: 600;
  text-align: center;
}
.service-page-horizontal-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);

  border-radius: 15px;
}

.service-page-horizontal-card-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.service-page-horizontal-card-right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 2rem; */
  font-size: 22px;
}
.service-page-horizontal-card-userimage {
  width: 100%;
  max-width: 300px;
  /* height: 300px; */
  /* border-radius: 50%; */
}

.service-page-ellipse-img {
  position: relative;
  width: 35%;

  border-radius: 100%;
}

.service-page-ellipse {
  padding: 0rem 1rem;

  display: flex;
  justify-content: space-around;
}

.director0,
.service-page-name-text0,
.service-page-service-page {
  position: relative;
  justify-content: center;
  line-height: 90%;
  text-transform: capitalize;
}

.service-page-service-page {
  font-size: 20px;
  font-family: var(--font-source-sans-pro);
  font-weight: 500;
  color: var(--color-dimgray-200);
}
.service-page-main {
  padding-bottom: 5rem;
  display: flex;
  gap: 1rem;
  /* min-height: calc(100vh - 75px); */
  justify-content: space-around;
  flex-direction: column;
}

.service-page-event-button {
  border-radius: 5px;
  background-image: var(--bg-image);
  box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
  width: 14vw;
  height: 50px;
  cursor: pointer;
  color: var(--color-white);
  border: 0px;
  font-size: medium;
  font-weight: 600;
  font-family: var(--font-family-space);
  border: 0px;
  text-align: center;

  margin-top: 2em;
}

.service-page-event-button:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.service-page-event-button:active,
.service-page-event-button:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.service-page-App {
  padding: 2rem 0rem;
}

.service-page-cards-row {
  display: flex;
  flex-wrap: wrap;
  flex:1;
  justify-content: space-around;
  width: 100%;
  height: 250px;
  gap: 2rem;
}
.service-page-vertical-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 250px; */
  min-height: 200px;
  height: 100%;
  backdrop-filter: blur(30px);
  padding: 1rem;
  box-shadow: 0px 0px 18px 0px var(--fg-color);
  border-radius: 15px;
}

.service-page-vertical-card-bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  justify-content: center;
  align-items: center;
  text-align: center;
}
.service-page-vertical-card-bottom p {
  text-align: center;
}

.service-page-vertical-name-text {
  font-size: 20px;
  font-family: var(--font-family-akira);
  font-weight: 800;
  text-align: center;
  color: var(--fg-color);
  width: 100%;
  /* max-width: 250px; */
  text-transform: uppercase;
}

#service-details {
  display: flex;
  flex-direction: row;
}

.service-page-item {
  flex: 1;
}

/* .service-page-comparison-table, */
.service-page-comparison-table td {
  border: solid 3px gray;
}

.service-page-comparison-table td:first-child {
  text-align: left;
}

.service-page-comparison-table td {
  text-align: center;
  font-size: 20px;
  padding: 0.5rem 1rem;
}

.service-page-comparison-table th:first-child {
  border: solid 3px var(--fg-color);
  text-align: left;
  padding: 0.5rem 1rem;
}

.service-page-comparison-table th {
  border: solid 3px var(--fg-color);
  width: 200px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  font-size: 24px;
  font-family: var(--font-family-akira);
  color: var(--fg-color);
}
.service-page-testi-card-rating-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 5px;
}
.service-page-testi-card-cite {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.service-page-testimonial-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  /* max-width: 250px; */
  min-height: 225px;
  height: 100%;
  backdrop-filter: blur(30px);
  padding: 1rem;
  box-shadow: 0px 0px 18px 0px var(--fg-color);
  border-radius: 15px;
}
.service-page-faq-card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.service-page-faq-card {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.service-page-faq-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  /* max-width: 250px; */
  /* min-height: 225px; */
  height: 100%;
  backdrop-filter: blur(30px);
  padding: 1rem;
  box-shadow: 0px 0px 12px 0px var(--fg-color);
  border-radius: 15px;
}

.service-page-testimonial-card cite {
  align-self: flex-end;
}

.service-page-testimonial-cards-row {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}

.service-page-download-button {
  --main-focus: #2d8cf0;
  --font-color: #323232;
  --bg-color-sub: #dedede;
  --bg-color: #eee;
  --main-color: var(--fg-color);
  position: relative;
  width: 160px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 10px;
  overflow: hidden;
}

.service-page-download-button,
.service-page-download-button__icon,
.service-page-download-button__text {
  transition: all 0.3s;
}

.service-page-download-button .service-page-download-button__text {
  transform: translateX(22px);
  color: var(--font-color);
  font-weight: 600;
}

.service-page-download-button .service-page-download-button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 40px;
  background-color: var(--bg-color-sub);
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-page-download-btn-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex: 1;
}
.service-page-download-button .svg {
  width: 20px;
  fill: black;
}

.service-page-download-button:hover {
  background: var(--bg-color);
}

.service-page-download-button:hover .service-page-download-button__text {
  color: transparent;
}

.service-page-download-button:hover .service-page-download-button__icon {
  width: 100%;
  transform: translateX(0);
}

.service-page-download-button:active {
  transform: translate(3px, 3px);
  box-shadow: 0px 0px var(--main-color);
}
